// ** React Imports
import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";
//cache buster imports
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
// ** ThemeColors Context

import { themeConfig, ThemeContext, Spinner } from "@app/layout";

// ** ThemeConfig

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
// // ** Core styles
//core
import "primereact/resources/primereact.min.css";
// ** React Perfect Scrollbar

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './assets/scss/custom-variables.css';
import './assets/scss/style.scss'

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
const isDevelopment = process.env.NODE_ENV === 'development';

function RootApp({ props }) {
  return (
    <BrowserRouter >
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={!isDevelopment} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Spinner />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <ThemeContext>
              <LazyApp />
            </ThemeContext>
          </Suspense>
        </Provider>
      </CacheBuster>
    </BrowserRouter>
  )
}

export default RootApp
