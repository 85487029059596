import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@src/utility/api';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'lookups/fetchAll',
    async (params) => {
        const response = await api.get("/api/lookups/all", { params })
        return response.data;
    }
);



const invoicesSlice = createSlice({
    name: 'lookups',
    initialState: {
        items: {
            invoiceType: [],
            paymentMeans: [],
            tax: [],
            ownedSuppliers: [],
            partyIdentification: [],
        },
        loading: false,
        error: null,
    },
    reducers: {
        // Other reducer actions...
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                state.items = action.payload
            })

            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    state.loading = false;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                }
            )

    },
});


export default invoicesSlice.reducer;

