import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import toast from 'react-hot-toast'

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'refundRequests/fetchAll',
    async (params) => {

        const response = await api.post("/api/refund/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'refundRequests/fetchById',
    async ({ id, successCB }, { dispatch }) => {
        try {
            const response = await api.get(`/api/refundrequest/${id}`)
            successCB && successCB(dispatch, response.data)
            return response.data;
        } catch (error) { }

    }
);



export const fetchBookingDetailsByPnr = createAsyncThunk(
    'refundRequests/fetchBookingDetailsByPnr',
    async (id) => {
        const response = await api.get(`/api/BookingDetails/${id}`)
        return response.data;
    }
);

export const fetchBookingPaymentsByPnr = createAsyncThunk(
    'refundRequests/fetchBookingPaymentsByPnr',
    async (id) => {
        const response = await api.get(`/api/BookingPayments/${id}`)
        return response.data;
    }
);

export const fetchPassengerFlightsByPnr = createAsyncThunk(
    'refundRequests/fetchPassengerFlightsByPnr',
    async (id) => {
        const response = await api.get(`/api/PassengerFlights/${id}`)
        return response.data;
    }
);

export const create = createAsyncThunk(
    'refundRequests/create',
    async (payload) => {
        const response = await api.post(`/api/refundrequest`, payload)
        return response.data;
    }
);
export const addHistory = createAsyncThunk(
    'refundRequests/addHistory',
    async (payload) => {
        const response = await api.post(`/api/refundrequest/addhistory`, payload)
        return response.data;
    }
);
export const exportToExcel = createAsyncThunk(
    'refundRequests/exportToExcel',
    async (payload) => {
        const response = await api.post(`/api/refund-request/export-to-excel`, payload)
        return;
    }
);

export const completeRequest = createAsyncThunk(
    'refundRequests/completeRequest',
    async ({ payload }, { rejectWithValue }) => {
        try {
            var res = await api.post(`/api/refund-request/complete`, payload)
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }

        // return await api.post(`/api/refund-request/complete`, payload)
        //     .then((res) => res.data)
        //     .catch((err) => {
        //         console.log("err", err);
        //         return rejectWithValue(err.response.data)
        //     })
    }
);

export const getDashBoardSummaries = createAsyncThunk(
    'refundRequests/getDashBoardSummaries',
    async (payload) => {
        const response = await api.post(`/api/refund-request/dashboard-summaries`, payload)
        return response.data;
    }
);

const requestsSlice = createSlice({
    name: 'refundRequests',
    initialState: {
        items: [],
        loading: false,
        error: null,
        excelOpened: false,
        errors: null,
        payments: [],
        flights: [],
        bookingDetails: {},
        selectedItem: {
            refundRequest: { histories: [] },
        },
    },
    reducers: {
        // Other reducer actions...
        resetState: (state) => {
            state.payments = [];
            state.bookingDetails = {};
            state.flights = [];
            state.selectedItem = {
                refundRequest: { histories: [] },
            };
        },
        toggleExcel: (state, { payload }) => {
            state.excelOpened = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem.refundRequest = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchBookingDetailsByPnr.fulfilled, (state, action) => {
                state.bookingDetails = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchBookingPaymentsByPnr.fulfilled, (state, action) => {
                state.payments = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchPassengerFlightsByPnr.fulfilled, (state, action) => {
                state.flights = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(completeRequest.fulfilled, (state, action) => {
                const { successCallBack } = action.meta.arg;
                toast.success('Request submitted successfully');
                successCallBack && successCallBack(action.payload)
            })
            .addCase(exportToExcel.fulfilled, (state, action) => {
                toast.success('Request Executed Successfully, you will receive an email with the excel file')
                state.excelOpened = false;
            })


            .addCase(getDashBoardSummaries.fulfilled, (state, action) => {
                state.statusSummary = action.payload.statusSummary;
                state.reasonSummary = action.payload.reasonSummary;
                state.requestsSummary = action.payload.requestsSummary;
            })


            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => {
                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = true;
                    state[`${actionName}_error`] = null;
                    state[`${actionName}_errors`] = null;

                    // should be updated 
                    state.loading = true;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = false;
                    state[`${actionName}_error`] = null;
                    state[`${actionName}_errors`] = null;

                    // should be updated 
                    state.loading = false;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action, payload) => {

                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = false;
                    state[`${actionName}_error`] = action.error.message;
                    state[`${actionName}_errors`] = action.payload?.errors;

                    console.log("action", action);
                    const { failCallBack } = action.meta.arg;
                    toast.error(action.payload?.data?.errorMessage ?? "Somthing went wronge please try again later...");
                    failCallBack && failCallBack(action.payload?.errors || {})

                    // should be updated 
                    state.loading = false;
                    state.error = action.error.message;
                    state.errors = action.payload?.errors;
                }
            )
    },
});


export const { resetState, toggleExcel } = requestsSlice.actions
export default requestsSlice.reducer;

